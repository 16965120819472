 

  .no-bullet-list {
    margin-bottom: 8px;
    margin-left: -40px;
    list-style-type: none;
  }

  .input-field {
    width:100px;
    height:20px;
    padding: 3px;
    margin-left:  3px;
    background-color: #f0f0f0;
    border: 1px solid #b0b0b0;
    border-radius: 3px;
    outline: none;
  }



