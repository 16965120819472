.App {
  background: lightgray;
  text-align: left;
}

body {
  margin: 0px;
}
.container {
  
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: nowrap column;
  overflow:hidden;
}

.panel {    
  display: flex;
  margin: 5px;
}

.header {
  background: lightgray;
  min-height: 50px;
  height: 50px;
}
.footer {
  background: lightgray;
  height: 100%;
  display:flex;
  flex-flow: column nowrap;
  align-items: stretch;
  text-align: left;
  justify-content: flex-start;
  overflow: hidden;
}

.footerArea{
  flex-grow: 1;
  display: flex;
  min-height: 100px;
  flex-flow: column;
  overflow-y: auto;
}
.footerAreaContent {
  min-height: 50px;
  flex:auto;
  height: 100%;
}
.footerBottomBar {
  background: gray;
  color: white;
  padding: 10px
}
.body {
  background: lightgray;
  flex-grow: 3;
  display: flex;
  flex-flow: row nowrap;
}

.sideBarResizeContainer {
  width: 30%;
}
.sidebar {
  background: lightgray;
  min-width: 200px;
  width: 400px;
 
  box-sizing: border-box;
    flex-grow: 1;
}


.withMargin {
  margin: 10px;
  box-sizing: border-box;
}

.content {
  flex-grow: 2;  
  background: white; 
}

.tabbar {
  height: 40px
}

.resource-Panel {
   display: flex;
    flex-direction: column;
    width: 100%;
    height:100%
}

.resource-Panel-Row {
  display: flex;
   flex-direction: row;
   width: 100%;
   height:100%
}

.tab-Content {
  flex: auto;
  height: 100%
}

.tab-Fixed-Content {
  flex: none;
  height: 40px
}

